var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.workOrdersFiltered,"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":_vm.$vuetify.breakpoint.mdAndDown ? '69vh' : '64vh',"multiple-expand":"","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mt-0 mx-0 pt-4 pb-0 pb-4",style:({ 'background-color': '#eeeeee' }),attrs:{"no-gutter":""}},[_c('v-col',{staticClass:"d-flex mb-n3",attrs:{"cols":"12"}},[_c('h1',{staticClass:"mr-4"},[_vm._v("WORK ORDERS BALANCE")])])],1)]},proxy:true},{key:`header.code`,fn:function({ header }){return [_c('v-text-field',{staticClass:"py-2 mt-2",attrs:{"label":header.text,"dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}},{key:`header.project`,fn:function({ header }){return [_c('v-text-field',{staticClass:"py-2 mt-2",attrs:{"label":header.text,"dense":""},model:{value:(_vm.projectToFilter),callback:function ($$v) {_vm.projectToFilter=$$v},expression:"projectToFilter"}})]}},{key:`item.projectManager`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.projectManager.name))])])]}},{key:`item.project`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.project.reference)+" - "+_vm._s(item.project.name)+" ")])])]}},{key:`item.woQty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(_vm.getTotalWOQty(item))+" ")])])]}},{key:"expanded-item",fn:function({
                headers,
                item: item,
            }){return [_c('td',{staticClass:"pa-4 ma-0 outlined",style:({ background: 'white' }),attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.subheaders,"items":item.items,"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.qty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.quantity)+" ")])])]}},{key:`item.releasedQty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.released)+" ")])])]}},{key:`item.receivedQty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.received)+" ")])])]}},{key:`item.shippedQty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.shipped)+" ")])])]}},{key:`item.balanceQty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.balance)+" ")])])]}},{key:`item.pendingQty`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s((item.released || 0) - (item.received || 0))+" ")])])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',[_c('v-menu',{attrs:{"rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.openDialogPackingBalance(
                                                        item,
                                                        'delivery'
                                                    )}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-send")])],1),_c('v-list-item-title',[_vm._v(" Delivery log ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogPackingBalance(
                                                        item,
                                                        'quality'
                                                    )}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-list-item-title',[_vm._v(" Quality log ")])],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"1200px"},model:{value:(_vm.itemLogDialog),callback:function ($$v) {_vm.itemLogDialog=$$v},expression:"itemLogDialog"}},[(_vm.itemLogDialog)?_c('ItemLog',{attrs:{"viewType":_vm.viewLogType,"users":_vm.users,"selectedItem":_vm.selectedItem},on:{"closeDialog":function($event){_vm.itemLogDialog = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }